












































import { Prop, Component, Vue } from 'vue-property-decorator'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import { EFileFormat } from '@/models'
/**
 * f2-304b1
 * グループ メンバー招待他サークルから選択（ポップアップ等）
 */
@Component
export default class ModalTopicSelectedFile extends Vue {
  @Prop({ default: 'display-files' }) private id!: string
  @Prop() private fileChooses!: any[]
  private eFileFormat = EFileFormat
  /**
   * Get file format by file extension
   */
  getFileFormat(fileName: string) {
    return CheckFileFormat.getFileFormatWithFileName(fileName)
  }

  /**
   * Change file to URL
   */
  readFileThumbnail(file: any) {
    const blob = new Blob([file])
    return URL.createObjectURL(blob)
  }

  /**
   * Get fontawsome icon by file extension
   */
  getIconName(fileName: string) {
    return CheckFileFormat.getIconClassNameWithFileName(fileName)
  }

  /**
   * Remove file by click 'x' in files display modal
   */
  removeFile(index: number) {
    this.$emit('remove', index)
  }

  handleFileNameWithSize(attachFile: any) {
    if (!attachFile.size) return attachFile.name
    const k = 1024
    const bytes = attachFile.id
      ? Number(attachFile.size) // this size is from API and this is KB
      : Number(attachFile.size / k) //this size is byte
    attachFile.id
    let sizeText = '0 KB'
    if (bytes) {
      if (bytes < 1) {
        sizeText = '1 KB'
      } else {
        const sizes = ['KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        sizeText =
          parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
      }
    }
    return attachFile.name + ` (${sizeText})`
  }
}
